/*
 * File: CGVService.ts
 * Project: autobuyclient
 * File Created: Tuesday, 19th September 2023 06:09:03
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 30th October 2023 03:29:07
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Config from "Config/Config";
import { buildAxiosHeader } from "Utils/Request.utils";
import axios from "axios";

export default class CGVService {
    static async setCGVRequestStatus(userID: string, status: "success" | "failure", sr_id: string) {
        const myUrl = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CGVSIGNING}`;
        try {
            const cgvStatus = await axios.put(myUrl, {userID, status, sr_id}, buildAxiosHeader());
            return cgvStatus.data;
        } catch (e: any) {
            console.error("Unable to set signature status.")
            return null;
        }
    }
}
